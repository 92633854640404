import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../templates/mwg-cp/homepageView.vue')
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('../templates/mwg-cp/aboutUsView.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('../templates/mwg-cp/contactUsView.vue')
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('../templates/mwg-cp/teamView.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../templates/mwg-cp/JoinView.vue')
  },
  {
    path: '/join/:any',
    name: 'join-detail',
    component: () => import('../templates/mwg-cp/JoinDetailView.vue')
  },
  {
    path: '/apply/:any',
    name: 'apply',
    component: () => import('../templates/mwg-cp/ApplyView.vue')
  },
  {
    path: '/boola-boodjar',
    name: 'boola-boodjar',
    component: () => import('../templates/mwg-cp/BoolaBoodjarView.vue')
  },
  {
    path: '/policy',
    name: 'privacy-policy',
    component: () => import('../templates/mwg-cp/PrivacyPolicyView.vue')
  },
  {
    path: '/services',
    name: 'service',
    component: () => import('../templates/mwg-cp/servicesView.vue')
  },
  {
    path: '/services/:any',
    name: 'service-detail',
    component: () => import('../templates/mwg-cp/serviceDetailView.vue')
  },
  {
    path: '/login',
    name: 'login-juga',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/LogoutView.vue')
  },
  {
    path: '/:any',
    name: '404',
    component: () => import('../templates/mwg-cp/404.vue')
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
