
//store/modules/auth.js

import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async getTokenLogs({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }

        console.log(headers,"TOKEN")

        const response = await axios.get('token-list',{ params: form, headers: headers})
        return response
    },
};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
