

import { createStore } from 'vuex'

import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import uploader from './modules/uploader';
import medicaltask from './modules/medicaltask';
import symptom from './modules/symptom';
import spot from './modules/spot';
import objective from './modules/objective';
import medicalrecord from './modules/medicalrecord';
import doctor from './modules/doctor';
import packages from './modules/packages';
import product from './modules/product';
import subscription from './modules/subscription';
import user from './modules/user';
import purchase from './modules/purchase';
import token from './modules/token';
import group from './modules/group';
import hospital from './modules/hospital';
import location from './modules/location';
import unit from './modules/unit';
import nurse from './modules/nurse';
import poly from './modules/poly';
import staff from './modules/staff';
import patient from './modules/patient';
import drug from './modules/drug';
import appointment from './modules/appointment';
import dashboard from './modules/dashboard';

import recruitwizard from './modules/recruitwizard';
import job from './modules/job';
import contact from './modules/contact';

export default createStore({
  modules: {
    auth,
    uploader,
    medicaltask,
    symptom,
    spot,
    objective,
    medicalrecord,
    doctor,
    packages,
    product,
    subscription,
    user,
    purchase,
    token,
    group,
    hospital,
    location,
    unit,
    nurse,
    poly,
    staff,
    patient,
    drug,
    appointment,
    dashboard,

    recruitwizard,
    job,
    contact
  },
  plugins: [createPersistedState()]
});
