//store/modules/auth.js

import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async searchDrug({dispatch}, form) {
        const headers =  {
            Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
        }

        const response = await axios.get('drug-search', { params: form, headers: headers})
        return response
    },

    async getDrugs({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }


        const response = await axios.get('drug-list',{ params: form, headers: headers})
        return response
    },

    async createDrug({dispatch}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        }

        const response = await axios.post('drug-create',form, headers)
        return response
    },
    
    async updateDrug({dispatch}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        }

        const response = await axios.post('drug-update',form, headers)
        return response
    },
    
    async detailDrug({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        
        const response = await axios.get('drug-detail',{ params: form, headers: headers})
        return response
    },
    
    async deleteDrug({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        
        const response = await axios.delete('drug-delete',{ params: form, headers: headers})
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
