import axios from 'axios';
import MainApp from '@/router'

const state = {
    
};

const getters = {
    
};

const actions = {
   
    async upload({dispatch}, form) {

        const response = await axios.post('medirec/upload', form, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        })

        return response
    },
    
    async uploadsingle({dispatch}, formData){
        const headers = { 'Content-Type': 'multipart/form-data' }
        return axios.post('medirec/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
         })
    },
    
    async replaceFile({dispatch}, formData){
        const headers = { 'Content-Type': 'multipart/form-data' }
        return axios.post('medirec/upload/replace', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
         })
    },

    setUserPhone({commit}, phone){
        state.phone = phone
    }
};

const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations
};
