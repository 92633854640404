import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import axios from 'axios';

import VueCookies from 'vue-cookies'
import BootstrapVue3 from "bootstrap-vue-3";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import VueGoogleMaps from '@fawmi/vue-google-maps'
import './assets/scss/style.scss'
import './assets/scss/mwgcp.scss'
import './assets/scss/transition.scss'

import './registerServiceWorker'
import { Head } from '@inertiajs/vue3'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowLeft, 
  faClock, 
  faClockRotateLeft, 
  faEllipsis, 
  faPowerOff, 
  faQrcode, 
  faUser, 
  faAngleRight, 
  faCheck, 
  faCheckCircle, 
  faSearch, 
  faPlus, faScissors, faBroom, faFire, faRecordVinyl, faChartLine, faMinimize, faMaximize, 
  faCog, 
  faXmark, 
  faImage, 
  faCircle, 
  faCircleCheck, 
  faEllipsisVertical, 
  faAngleLeft,
  faFileLines,
  faDatabase,
  faBedPulse,
  faFile,
  faBox,
  faBoxes,
  faShop,
  faCertificate,
  faShoppingBag,
  faUserDoctor,
  faUserNurse,
  faHospital,
  faBuilding,
  faStethoscope,
  faCalendar,
  faRocket,
  faCalendarAlt,
  faKitMedical,
  faSyringe,
  faChartGantt,
  faChartBar,
  faChartPie,
  faCloudBolt,
  faCloudUpload,
  faEnvelope,
  faMapMarker,
  faAngleDown,
  faMobile,
  faGlobe,
  faCloudArrowUp,
  faArrowDownWideShort,
  faPhone} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faFacebookF, faLinkedin, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

library.add(faArrowLeft,
  faClock, 
  faClockRotateLeft, 
  faEllipsis, 
  faQrcode, 
  faUser, 
  faPowerOff, 
  faAngleRight, 
  faAngleLeft,
  faCheckCircle, 
  faCheck, 
  faSearch, 
  faPlus, 
  faScissors, 
  faBroom, 
  faFire,
  faRecordVinyl, 
  faChartLine,
  faMinimize,
  faMaximize, 
  faCog, 
  faXmark, 
  faImage, 
  faCircle, 
  faCircleCheck, 
  faEllipsisVertical,
  faFileLines,
  faDatabase,
  faBedPulse,
  faFile,
  faBox,
  faBoxes,
  faShop,
  faCertificate,
  faShoppingBag,
  faUserDoctor,
  faUserNurse,
  faHospital,
  faBuilding,
  faUser,
  faStethoscope,
  faCalendar,
  faRocket,
  faCalendarAlt,
  faKitMedical,
  faSyringe,
  faChartBar,
  faChartPie,
  faCloudBolt,
  faCloudUpload,
  faLinkedinIn,
  faFacebookF,
  faEnvelope,
  faMapMarker,
  faAngleDown,
  faMobile,
  faGlobe,
  faCloudArrowUp,

  faArrowDownWideShort,
  faPhone
  )

axios.defaults.baseURL = "https://api.tonnesplus.com/api/";
// axios.defaults.baseURL = "http://127.0.0.1:8004/api/";

import ZoomOnHover from "vue-zoom-on-hover";
import VueMeta from 'vue-meta';

const MainApp = createApp(App).component('font-awesome-icon', FontAwesomeIcon)
    .use(store)
    .use(VueMeta)
    .use(BootstrapVue3)
    .use(ZoomOnHover)
    .use(router)
    .use(VueCookies)
    .use(store)
    .use(Head)
    .use(VueGoogleMaps, {
      load: {
          key: 'AIzaSyCHBtx96V3XiITY2KKoJVxTKe1xsm5Ifhk',
      },
    })
    .mount('#app')