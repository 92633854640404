//store/modules/auth.js

import axios from 'axios';
import MainApp from '@/router'

const cors = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*' // Could work and fix the previous problem, but not in all APIs
      }
}

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    
    async searcStaff({dispatch}, form) {
        const headers =  {
            Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
        }

        const response = await axios.post('staff-search', form)
        return response
    },

    async getStaffs({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }

        console.log(headers,"TOKEN")

        const response = await axios.get('staff-list',{ params: form, headers: headers})
        return response
    },

    async createStaff({dispatch}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        }

        const response = await axios.post('staff-create',form, headers)
        return response
    },
    
    async updateStaff({dispatch}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        }

        const response = await axios.post('staff-update',form, headers)
        return response
    },
    
    async detailStaff({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        
        const response = await axios.get('staff-detail',{ params: form, headers: headers})
        return response
    },
    
    async deleteStaff({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        
        const response = await axios.delete('staff-delete',{ params: form, headers: headers})
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
