//store/modules/auth.js

import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async getPurchaseSet({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }

        console.log(headers,"TOKEN")

        const response = await axios.get('purchase-set',{ params: form, headers: headers})
        return response
    },

    async getPurchases({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }

        console.log(headers,"TOKEN")

        const response = await axios.get('purchase-list',{ params: form, headers: headers})
        return response
    },

    async createPurchase({dispatch}, form) {
        const headers =  {
            headers: {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        }

        const response = await axios.post('purchase-create',form, headers)
        return response
    },
    
    async updatePurchase({dispatch}, form) {
        const headers =  {
            headers: {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        }

        const response = await axios.put('purchase-update',form, headers)
        return response
    },
    
    async detailPurchase({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        
        const response = await axios.get('purchase-detail',{ params: form, headers: headers})
        return response
    },
    
    async deletePurchase({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        
        const response = await axios.delete('purchase-delete',{ params: form, headers: headers})
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
