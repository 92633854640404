//store/modules/auth.js

import axios from 'axios';
import MainApp from '@/router'

const state = {
    user: null,
    posts: null,
    token: null,
    phone: null,
    worker: null
};

const getters = {
    isAuthenticated: function(){
        const account = $cookies.get("MEDIREC_USER")
        console.log(account,"ACC")
        if(account!=null || account!=undefined){
            return true
        }else{
            return state.user
        }
    },    
    StateUser: function(){
        if(state.user){
            return state.user
        }else{
            return $cookies.get("MEDIREC_USER")
        }
    },

    userToken: function(){
        if(state.token){
            return state.token
        }else{
            return $cookies.get("MEDIREC_TOKEN")
        }
    },

    getPhone: function(){
        return state.phone
    },

    getUser: function(){

        if(state.user){
            return state.user
        }else{
            return $cookies.get("MEDIREC_USER")
        }
    },

    getWorker: function(){
        if(state.worker){
            return state.worker
        }else{
            return $cookies.get("MEDIREC_WORKER")
        }
    }
};

const actions = {
    checkAuth({dispatch},url){
        const user = $cookies.get("MEDIREC_USER")
        if(!user ){
            if(url.path!='/login'){
                MainApp.push("/login")
            }
        }
    },
    async Register({dispatch}, form) {
        const response = await axios.post('register', form)
        return response
        // await dispatch('LogIn', UserForm) //call to another actions
    },

    async ConfirmOTP({dispatch}, form) {
        const response = await axios.post('confirm-otp', form)
        return response
    },

    async changePIN({dispatch}, form) {
        const response = await axios.post('change-pin', form)
        return response
    },

    async Login({commit}, form) {
        const response = await axios.post('login', form)
        
        await commit('setUser', response.data.user)
        return response
    },

    async updateProfile({dispatch}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        }

        const response = await axios.post('profile-update',form, headers)
        return response
    },

    async getProfile({dispatch}, form) {
        const headers =  {
            Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
        }


        const response = await axios.get('user-detail',{ params: {id: form.id}, headers: headers})
        return response
    },

    setLogin({commit}, data){
    
        $cookies.set("MEDIREC_TOKEN",data.token)
        $cookies.set("MEDIREC_USER",data.user)
        $cookies.set("MEDIREC_WORKER",data.user.worker)
        console.log(data,"DATANYA")
        commit('setuserdata',data)
    },

    async Logout({dispatch}){
        let user = null
        $cookies.remove("MEDIREC_TOKEN")
        $cookies.remove("MEDIREC_USER")
        $cookies.remove("MEDIREC_WORKER")
        
        return true;
    },

    setUserPhone({commit}, phone){
        state.phone = phone
    }
};

const mutations = {
    setUser(state, username){
        state.user = username
    },
    setPosts(state, posts){
        state.posts = posts
    },
    logout(state){
        state.user      = null
        state.worker   = null
        state.token     = null
    },
    setuserdata(state,data){
        state.user      = data.user
        state.worker    = data.worker 
        state.token     = data.token

        console.log(state,"STATES")
    }
};

export default {
  state,
  getters,
  actions,
  mutations
};
