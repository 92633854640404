import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async getRecruitWizardData({dispatch}, form) {

        const response = await axios.get('https://app.recruitwizard.com/api/adverts/vtWLg88jrvZ/json')
        return response
    }

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
