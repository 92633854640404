//store/modules/auth.js

import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {

    async searchSubscription({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }

        const response = await axios.get('subscription-search',{ params: form, headers: headers})
        return response
    },

    async getSubscriptionSet({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }

        const response = await axios.get('subscription-set',{ params: form, headers: headers})
        return response
    },

    async getSubscriptions({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }

        const response = await axios.get('subscription-list',{ params: form, headers: headers})
        return response
    },

    async createSubscription({dispatch}, form) {
        const headers =  {
            headers: {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        }

        const response = await axios.post('subscription-create',form, headers)
        return response
    },
    
    async updateSubscription({dispatch}, form) {
        const headers =  {
            headers: {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        }

        const response = await axios.put('subscription-update',form, headers)
        return response
    },
    
    async detailSubscription({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        
        const response = await axios.get('subscription-detail',{ params: form, headers: headers})
        return response
    },
    
    async deleteSubscription({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        
        const response = await axios.delete('subscription-delete',{ params: form, headers: headers})
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
