import axios from 'axios';
import MainApp from '@/router'

const state = {
    
};

const getters = {
    
};

const actions = {
    async getAppointments({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }

        console.log(headers,"TOKEN")

        const response = await axios.get('appointment-list',{ params: form, headers: headers})
        return response
    },


    async deleteAppointment({dispatch}, form) {
        const headers =  {
                Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
            }
        
        const response = await axios.delete('appointment-delete',{ params: form, headers: headers})
        return response
    },
};

const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations
};
