import axios from 'axios';
import MainApp from '@/router'

const state = {
    
};

const getters = {
    
};

const actions = {
    async sendToDataCutter({dispatch}, form){
        return axios.post('medirec/cutter/sendtocutter', form)
    },
    
    async linkMedicalToPatient({dispatch}, form){
        return axios.post('medirec/cutter/link', form)
    },
    
    async getDetailTask({dispatch}, form){
        return axios.post('medirec/cutter/detail', form)
    },
    
    async getCutterTask({dispatch}, form){
        return axios.post('medirec/cutter', form)
    },
    
    async getUploadedFiles({dispatch}, form){
        const headers =  {
            Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
        }

        return axios.get('medirec/uploader', { params: form, headers: headers})
    },
    
    async getCleanerTask({dispatch}, form){
        const headers =  {
            Authorization: $cookies.get("MEDIREC_TOKEN") ? `Bearer ${$cookies.get("MEDIREC_TOKEN")}` : '',
        }

        return axios.get('medirec/cleaner',  { params: form, headers: headers})
    },

    async remoteTask({dispatch}, form){
        return axios.post('medirec/remove', form)
    },
    
    async setTaskDone({dispatch}, form){
        return axios.post('medirec/setdone', form)
    },
    
    async assignTo({dispatch}, form){
        return axios.post('medirec/assignment', form)
    },

    setUserPhone({commit}, phone){
        state.phone = phone
    }
};

const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations
};
